import React from "react";

const Footer: React.FC = () => (
  <footer>
    <nav>
      <ul>
        <li>
          <a href="/">Portfolio</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/downloads/sonalizaveri_resume.pdf">Resume</a>
        </li>
        <li>
          <a target="blank" href="https://linkedin.com/in/sdzaveri">
            LinkedIn
          </a>
        </li>
      </ul>
    </nav>
    <p>© 2022 Sonali Dujari Zaveri</p>
  </footer>
);

export default Footer;
