import React, { useState } from "react";
import Logo from "@/images/sdzaveri-logo.svg";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";

const Header: React.FC = ({ active }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    } else {
      setMobileMenuOpen(true);
    }
  };

  return (
    <header>
      <a href="/">
        <img src={Logo} alt="" className="header__logo" />
      </a>
      <nav className="desktop-navigation">
        <ul>
          <li>
            <a href="/" className={active === "index" ? "active" : ""}>
              Portfolio
            </a>
          </li>
          <li>
            <a href="/about" className={active === "about" ? "active" : ""}>
              About
            </a>
          </li>
          <li>
            <a href="/downloads/sonalizaveri_resume.pdf" target="blank">
              Resume
            </a>
          </li>
          <li>
            <a target="blank" href="https://linkedin.com/in/sdzaveri">
              LinkedIn
            </a>
          </li>
        </ul>
      </nav>
      <button
        className="mobile-navigation-toggle"
        type="button"
        onClick={toggleMobileMenu}
        title="Open Menu"
      >
        <MenuIcon />
      </button>
      <Drawer anchor="right" open={mobileMenuOpen} onClose={toggleMobileMenu}>
        <nav>
          <ul>
            <li>
              <button onClick={toggleMobileMenu} type="button">
                Close
              </button>
            </li>
            <li>
              <a href="/" className={active === "index" ? "active" : ""}>
                Portfolio
              </a>
            </li>
            <li>
              <a href="/about" className={active === "about" ? "active" : ""}>
                About
              </a>
            </li>
            <li>
              <a href="/downloads/sonalizaveri_resume.pdf" target="blank">
                Resume
              </a>
            </li>
            <li>
              <a target="blank" href="https://linkedin.com/in/sdzaveri">
                LinkedIn
              </a>
            </li>
          </ul>
        </nav>
      </Drawer>
    </header>
  );
};

export default Header;
